
import * as vercelRuntime$syxe3zF0BBSkb_45422EAl3vW_45zwvKKLZtJjVPs2FG_DQ from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1440,
    "xxl": 1728,
    "2xl": 1536
  },
  "presets": {
    "small_vehicle": {
      "modifiers": {
        "format": "jpg",
        "width": 140,
        "height": 115
      }
    },
    "vehicle": {
      "modifiers": {
        "format": "jpg",
        "width": 245,
        "height": 164
      }
    }
  },
  "provider": "vercel",
  "domains": [
    "*.vercel.app",
    "vercel.app",
    "checkout.test.cycle.eco",
    "checkout.cycle.eco",
    "subv2.test.cycle.eco",
    "subscriptions.cycle.eco"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$syxe3zF0BBSkb_45422EAl3vW_45zwvKKLZtJjVPs2FG_DQ, defaults: {} }
}
        